import React from "react";
import Img from "gatsby-image";
import Container from "../components/container";
import Layout from "../components/layout";
import { KeofittAboutData } from "../model";
import { graphql } from "gatsby";
const logo = require("../../static/logo/keofitt_logo.svg") as string;

export const query = graphql`
  query {
    image: file(name: { eq: "the_drop_of_water" }) {
      id
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const About = ({ data }: { data: KeofittAboutData }) => {
  return (
    <Layout title="About" padding>
      <Container>
        <div className="max-w-xs mx-auto" key={data.image.id}>
          <Img fluid={data.image.childImageSharp.fluid} />
        </div>
        <div className="py-8 mx-auto text-center fairytale">
          <p>
            One year. Twelve months and 365 days that will eventually become
            memories representing that year. Memories to cherish and to learn
            from. Just like samples.
          </p>
          <p>
            Hans Christian Andersens fairy tales and fables are well worth
            sampling. Wisdom in its purest and most entertaining form. Danish
            artist Jeppe Eisner has transformed twelve of these samples into
            fascinating paintings. One for every month. Enjoy!
            <br />
            <br />
            Best regards <br />
            Henrik Lysgaard Salomon
          </p>
        </div>
        <img className="max-w-sm mx-auto" alt="logo" src={logo} />
      </Container>
    </Layout>
  );
};

export default About;
